exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-jsx": () => import("./../../../src/pages/about.jsx" /* webpackChunkName: "component---src-pages-about-jsx" */),
  "component---src-pages-acontact-jsx": () => import("./../../../src/pages/acontact.jsx" /* webpackChunkName: "component---src-pages-acontact-jsx" */),
  "component---src-pages-approach-jsx": () => import("./../../../src/pages/approach.jsx" /* webpackChunkName: "component---src-pages-approach-jsx" */),
  "component---src-pages-case-studies-jsx": () => import("./../../../src/pages/caseStudies.jsx" /* webpackChunkName: "component---src-pages-case-studies-jsx" */),
  "component---src-pages-contact-jsx": () => import("./../../../src/pages/contact.jsx" /* webpackChunkName: "component---src-pages-contact-jsx" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-insights-jsx": () => import("./../../../src/pages/insights.jsx" /* webpackChunkName: "component---src-pages-insights-jsx" */),
  "component---src-pages-services-jsx": () => import("./../../../src/pages/services.jsx" /* webpackChunkName: "component---src-pages-services-jsx" */)
}

